.btn-next {
    background-color: #3A435E;
    color:#FFF;
    border-color: #3A435E;
 }

 .btn-previous {
    background-color: #3A435E;
    color:#FFF;
    border-color: #3A435E;    
 }

 .btn-remove-language {
    background-color: #CE2D4F;
    color:#FFF;
    border-color: #CE2D4F;
 }

 .btn-submit {
    background-color: #45B11B;
    color:#FFF;
    border-color: #45B11B;    
 }

 .btn-next:active:focus {
   background-color: #3A435E;
   color:#FFF;
   border-color: #3A435E;
 }

 .btn-previous:active:focus {
   background-color: #3A435E;
   color:#FFF;
   border-color: #3A435E;    
 }

 .btn-remove-language:active:focus {
   background-color: #CE2D4F;
   color:#FFF;
   border-color: #CE2D4F;
 }

 .btn-submit:active:focus {
   background-color: #45B11B;
   color:#FFF;
   border-color: #45B11B;   
 }

 .btn-next:hover {
    background-color: #1B8EB7;
    color:#FFF;
    border-color: #1B8EB7;
}
option *{
   font-family: 'Quicksand', sans-serif !important;

}
.btn-previous:hover {
    background-color: #1B8EB7;
    color:#FFF;
    border-color: #1B8EB7;
}

.btn-remove-language:hover {
    background-color: #CE6D8B;
    color:#fff;
    border-color: #CE6D8B;
}

.btn-submit:hover {
    background-color: #8DE969;
    color:#fff;
    border-color: #8DE969;
}

.font-link {
    font-family: 'Quicksand', sans-serif !important;
}
