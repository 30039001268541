@media  (min-width: 991px)  {
    
    .login{
        height: 700px;
        min-height: 100vh;
        background: url("./../../img/bg13.svg");
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: repeat;
        background-repeat: no-repeat;
        background-position: calc(62% + 1200px) center;
    }
}

html{
    width:100%;
    height: 100%;
}


.login {
        min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #3A435E;
}

*, ::before, ::after {
    box-sizing: border-box;
}

.login-card-body {
    flex: auto;
    padding: 40px;
    background-color: #EFF1F3;
}

.font-link {
    font-family: 'Quicksand', sans-serif;;
}

.btn-login {
    background-color: #45B11B;
    color:#FFF;
    border-color: #45B11B;    
 }

.btn-login:active:focus {
    background-color: #45B11B;
    color:#FFF;
    border-color: #45B11B;   
  }

.btn-login:hover {
    background-color: #8DE969;
    color:#fff;
    border-color: #8DE969;
}
