$text-color: white;
$background-color-active: #CE2D4F;
$background-color-disabled: #ddd;
// $background-color: #bbb;
$background-color: #3A435E;

.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;

    &-checkbox {
      display: none;
    }

    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid $background-color;
      border-radius: 20px;
      margin: 0;
    }

    &-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;

      &:before {
        content: attr(data-yes);
        text-transform: uppercase;
        
        background-color: $background-color-active;
        color: $text-color;
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        padding-left: 10px;
        line-height: 34px;
        font-size: 14px;
        color: $text-color;
        font-weight: bold;
        box-sizing: border-box;
        background-color: $background-color-active;
      }
    }

    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        background-color: $background-color;
        color: $text-color;
        text-align: right;
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        padding-right: 10px;
        line-height: 34px;
        font-size: 14px;
        color: $text-color;
        font-weight: bold;
        box-sizing: border-box;
    }

    &-disabled {
      background-color: $background-color-disabled;
      cursor: not-allowed;
    }

    &disabled:before {
        background-color: $background-color-disabled;
        cursor: not-allowed;
    }

    &-disabled:after {
        background-color: $background-color-disabled;
    }

    &-switch {
      display: block;
      width: 24px;
      margin: 5px;
      background: $text-color;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 40px;
      border: 0 solid $background-color;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }

    &-checkbox:checked + &-label {
      .toggle-switch-inner {
        margin-left: 0;
      }
      .toggle-switch-switch {
        right: 0px;
      }
    }

    &-small {
        width: 40px;
        .toggle-switch-inner {
          &:after, &:before {
            content: "";
            height: 20px;
            line-height: 20px;
          }
        }

        .toggle-switch-switch {
          width: 16px;
          right: 20px;
          margin: 2px;
        }
    }

    @media screen and (max-width: 991px) {
        transform: scale(0.9);
    }

    @media screen and (max-width: 767px) {
        transform: scale(0.825);
    }

    @media screen and (max-width: 575px) {
        transform: scale(0.75);
    }
}