/*
thead th {
    background-color: #044289;
    color: white;
  }
  tr td th {
    border-color: 1px solid black;
    border-spacing: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table {border-collapse: collapse;}
      table td { width: 50px; height: 50px;}
      table tr td {border: 1px solid #0000FF; }
  
  tr td{
    color: #005cc5;
  }
  
  */

  td, th {
    border: 1px solid #d3dbdf;
    padding: 5px;
}