.btn-exportpdf {
    background-color: #5E5345;
    color:#FFF;
    border-color: #5E5345;    
 }

.btn-exportpdf:active:focus {
    background-color: #5E5345;
    color:#FFF;
    border-color: #5E5345;   
  }

.btn-exportpdf:hover {
    background-color: #978772;
    color:#fff;
    border-color: #978772;
}

@media print{ body{ -webkit-print-color-adjust: exact; } }