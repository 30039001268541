// Options
// 
// Quickly modify global styling by enabling or disabling optional features.
  
$enable-rounded:            true !default;
$enable-shadows:            true;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Variables
//
// Colors

$theme-colors: (
  primary: #3A435E,
  secondary: #1B8EB7,
  light: #ffffff,
  dark: #151515,
  info: #ccc,
  success: #28a745,
  warning: #ffc107,
  danger: #dc3545
);

$body-bg: white;
$body-color: #333;


$body-color-inverse: invert($body-color) !default;
$link-color: #151515;

// Fonts
$font-family-base:'Quicksand', 'Fira Sans', 'Roboto', Helvetica, sans-serif; 
$headings-font-family: $font-family-base;
$display-font-family: $font-family-base;
$font-weight-normal: 400;
$headings-font-weight: 400;
$lead-font-size:   1.30rem;
$font-size-base: 1rem;
$spacer: 1.5rem;

body {
  font-family: "Quicksand", "Fira Sans", "Roboto", Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-align: left;
}


h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.75rem;
}
h4 {

    font-size: 1.5rem;
}
h5 {
    font-size: 1.25rem;
}


.btn {
    font-family: 'Quicksand', Helvetica, sans-serif !important;
    font-size: 1rem;
}
.form-control {
    font-family: 'Quicksand', Helvetica, sans-serif !important;
    font-size: 1rem;
}
#endyear {

        font-family: 'Quicksand', Helvetica, sans-serif !important;
        font-size: 1rem;
    
}
optgroup {
    font-family: 'Quicksand', Helvetica, sans-serif !important;
    font-size: 1rem;
}

.dropdown-menu {
    font-family: 'Quicksand', Helvetica, sans-serif !important;
    font-size: 1rem;
}

  @media (max-width: 991.98px){
.sidebar-show .main {
    position: fixed;
    height: 100vh;
}
.sidebar {
    position: fixed;
z-index: 1019;
width: 200px;
height: calc(100vh - 55px);
}}
//@media (max-width: 575.98px){
//.aside-menu-show .main, .sidebar-show .main {
//    position: relative;
//}}
.sidebar .nav {
    width: 200px;
    flex-direction: column;
    min-height: 100%;
    padding: 0;
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.app-body .main {
    flex: 1;
    min-width: 0;
}
.app-footer, .app-header, .aside-menu, .main, .sidebar {
    transition: margin-left .25s,margin-right .25s,width .25s,flex .25s;
}
.app-body {
    margin-top: 55px;
}
.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;
}
#basic-nav-dropdown::after{
  display: none; 
}

@media (min-width: 992px){
.header-fixed .app-body {
    margin-top: 55px;
}}
.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;
}

div {
    display: block;
}
.sidebar .sidebar-nav {
    overflow-x: hidden;
    overflow-y: auto;
}
.sidebar .sidebar-nav {
    position: relative;
    flex: 1;
    width: 200px;
}
.sidebar-nav {
    transition: width .25s;
}

*, ::after, ::before {
    box-sizing: border-box;
}
.sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #73818e;
    background: #f0f3f5;
}

.app-footer, .app-header, .aside-menu, .main, .sidebar {
    transition: margin-left .25s,margin-right .25s,width .25s,flex .25s;
}



.autocomplete{
    border: 1px solid #E4E7EA;

}


  
  /**
   * style input elements that have a required
   * attribute and a focus state
 
  input:required:focus {
    border: 1px solid #CE6D8B;
    outline: none;
  }
  
  
  //  style input elements that have a required
   // attribute and a hover state
   
  input:required:hover {
    opacity: 1;
  }
*/

 button:focus {
    box-shadow: none !important;
    outline: none !important; 
  }




  .nav-item{
      cursor: pointer;
  }

  @media print{ body{ -webkit-print-color-adjust: exact; } }

  @media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
    display: block;
    }
     
    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    }
     
    #no-more-tables tr { border: 1px solid #ccc; }
      
    #no-more-tables td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    padding-bottom: 30px;
    white-space: normal;
    text-align:left;
    }
     
    #no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: normal;
    text-align:left;
    font-weight: bold;
    }
     
    /*
    Label the data
    */
    #no-more-tables td:before { content: attr(data-title); }
    }

fieldset{
    border:#73818e;
}
.filter-sidebar{
    background: #f9f9f9 !important;
    border: 2px solid #f3f3f3;
    border-radius: 15px;
    box-shadow: 0px 0px 6px 1px #d1d1d1;
  }


.btn-primary {
    background-color: #3A435E;
    border-color: #3A435E;
    padding: .375rem .75rem;
    border-radius: 0.175rem;
      
 }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: #1B8EB7;
    color:#FFF;
    border-color: #1B8EB7;
}

.btn-danger {
    background-color: #CE2D4F;
    color:#FFF;
    border-color: #CE2D4F;    
 }

.btn-danger:active:focus {
    background-color: #CE2D4F;
    color:#FFF;
    border-color: #CE2D4F;   
  }

.btn-danger:hover {
    background-color: #CE6D8B;
    color:#fff;
    border-color: #CE6D8B;
}

.fa-primary{
    color: #3A435E;
}

.fa-primary:hover, .fa-primary:focus, .fa-primary:active {
    background-color: #1B8EB7;
    color:#FFF;
}

.feedback{
    color:red;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #3A435E;
    border-color: #3A435E;
  }
.nav-pills  .nav-item .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3A435E;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #3A435E;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }

.plus{

height: 14px;
width: 14px;
display: block;
color: white;
border: 2px solid white;
border-radius: 14px;
box-shadow: 0 0 3px #444;
box-sizing: content-box;
text-align: center;
text-indent: 0 !important;
font-family: 'Courier New', Courier, monospace;
line-height: 14px;
background-color: #31b131;
}
.minus{
    top:50%;
    right:50%;
    height: 14px;
    width: 14px;
    display: block;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 14px;
    background-color: red;
    }
  
    .no-cursor{
        cursor:default;
    }
  // import universis and bootstrap variables and mixins
@import "~@universis/theme/scss/universis";
@import "~@universis/theme/scss/coreui/_variables";
@import "~bootstrap/dist/css/bootstrap.min.css";
